<template>
  <div class="d-flex">
    <div v-for="(action, action_key) in actions" :key="action_key">
      <!-- Action Button ['type', 'square', 'icon', 'title', 'color' ,'action', 'hide'] -->
      <v-tooltip v-if="action.type === 'button' && !action.hide && action.tooltip && (!action.rights || $func.hasRight(action.rights))" bottom content-class="white--tooltip">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" small @click.stop="getLink(action)" :color="action.color ? action.color : ''" :class="'ml-3 white--text '+(action.class ? action.class : '')" :min-width="action.square ? '28' : ''" :max-width="action.square ? '28' : ''" depressed>
            <v-icon v-if="action.icon" color="white" :class="action.title ? 'mr-2' : ''" small> {{ action.icon }} </v-icon>
            {{ $t(action.title) }}
          </v-btn>
        </template>
        <span>{{ $t(action.tooltip) }}</span>
      </v-tooltip>
      <v-btn small @click.stop="getLink(action)" v-else-if="action.type === 'button' && !action.hide && (!action.rights || $func.hasRight(action.rights))" :color="action.color ? action.color : ''" :class="'ml-3 white--text '+(action.class ? action.class : '')" :min-width="action.square ? '28' : ''" :max-width="action.square ? '28' : ''" depressed>
        <v-icon v-if="action.icon" color="white" :class="action.title ? 'mr-2' : ''" small> {{ action.icon }} </v-icon>
        {{ $t(action.title) }}
      </v-btn>

      <!-- Action Menu ['type', 'title', 'color', 'menus', 'color'] -->
      <v-menu v-if="action.type === 'menu' && !action.hide && action.menus && canShowMenu(action)" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" :color="action.color ? action.color : ''" dark small class="ml-3" depressed>
            {{ $t(action.title) }}
            <v-icon class="ml-2" size="15"> $dropdown </v-icon>
          </v-btn>
        </template>
        <v-list dense v-for="(menu, menu_key) in action.menus" :key="menu_key">
          <v-divider v-if="menu_key !== 0"></v-divider>
          <v-list-item v-for="(menu_action, menu_action_key) in menu" v-if="!menu_action.hide && (!menu_action.rights || $func.hasRight(menu_action.rights))" :disabled="menu_action.disabled" :key="menu_action_key" @click="getLink(menu_action)" link>
            <v-list-item-title>{{ $t(menu_action.title) }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <v-dialog persistent width="400" v-model="showDownload">
        <v-card>
            <v-card-text class="pa-6">
                <h5 class="icon-title">
                {{$t('downloadReady')}}
                <v-btn @click="showDownload = false" text class="square">
                    <v-icon small>$close</v-icon>
                </v-btn>
                </h5>
                <div class="text-center mt-4 mb-2">
                <a :href="downloadUrl" @click="" download class="exportBtn">{{$t('clickForDownload')}}</a>
                </div>
                
            </v-card-text>
        </v-card>
    </v-dialog>
    <FormDialogComponent
        v-if="form"
        ref="componentForm"
        :form="form"
        :action="'SET_DATA'" />
    <ConfirmDialogComponent ref="confirmModal" />
  </div>
</template>

<script>
import GenericDataService from "@/services/GenericDataService";
import FormDialogComponent from '@/components/dialogs/FormDialogComponent'

import ConfirmDialogComponent from "@/components/dialogs/ConfirmDialogComponent";
import { mapActions } from "vuex";
import getForm from "@/mixins/mixins";

export default {
  name: "SubAction",
  props: ["subActions"],
  mixins: [getForm],
  components: {
    ConfirmDialogComponent,
    FormDialogComponent
  },
  data() {
    return {
      actions: {},
      api :this.$store.state.auth.apiUrl,
      form:null,
      downloadUrl:"",
      showDownload:false
    };
  },
  mounted() {
    this.actions = this.subActions;
  },
  watch: {
    subActions: function (val) {
      this.actions = val;
    },
  },
  methods: {
    //mapping des alertes
    ...mapActions("alerts", ["setAlert"]),
    //effacement d'un ou plusieur objet
    confirmElement(link, arrayIds) {
      if (link.confirm) {
        if (link.confirm.checkurl) {
          let linkToCheck = link.confirm.checkurl;
          this.getForm(linkToCheck+"?id="+arrayIds, arrayIds, link)
          // GenericDataService.postData(linkToCheck, arrayIds).then(
          //   (response) => {
          //     let checkData = response.data.data;
          //     let options = {
          //       needDoubleValidation: checkData.needDoubleValidation,
          //       confirmationImpacts: checkData.impacts,
          //       confirmationImpactTitle: this.$t(checkData.text),
          //       action: link.url,
          //       ids: arrayIds,
          //     };
          //     if(typeof checkData.deletable !== 'undefined' && checkData.deletable === false) {
          //       options.action = "";
          //       options.ids = [];
          //       let tmpLink = JSON.parse(JSON.stringify(link));
          //       tmpLink.confirm.message = this.$t(checkData.text);
          //       tmpLink.action = "";
          //       this.getForm(tmpLink, options, arrayIds)
          //     } else {
          //       this.getForm(link, options, arrayIds)
          //     }
          //   }
          //);
        } else {
          let options = {
            action: link.url,
            ids: arrayIds,
          };
          this.openConfirmModal(link, options, arrayIds);
        }
      }
    },
    // recupere l'action a faire selon le type du link (single et globaux)
    getLink(action) {
      if (action.action == "confirm" || action.action == "delete") {
        this.confirmElement(action, action.item ? [action.item.id] : []);
      } else if(action.action == "emit") {
        if(action.hasOwnProperty('hide')){ action.hide = !action.hide }
        let data = [];
        action.apiUrl ? data['apiUrl'] = action.apiUrl : null;
        this.$emit(action.emitAction, data, action)
      } else if(action.action == "actionMenus") {
        this.$emit(action.action, action)
      } else if(action.action == "form") {
        if (action.item) {
          this.$emit(action.action, action.formData.apiUrl, action.formData.mutation, action.url, [action.item.id], action)
        } else {
          this.$emit(action.action, (action.formData && action.formData.apiUrl ? action.formData.apiUrl : null), (action.formData && action.formData.mutation ? action.formData.mutation : null), action.url, [], action)
        }
      } else if(action.action == "get"){
        this.$router.push(action)
      } else if(action.action == "export") {
        let url = this.getExportUrl(action.id);
        GenericDataService.postData(url).then((response) => {
            if(response.data.data.directDownload){
                this.downloadUrl = this.api + response.data.data.directDownload;
                this.showDownload = true;
            }
        })
      }
      //this.$forceUpdate()
    },
    getExportUrl(retroId) {
        if(this.$store.state.auth.user.is_presta)
            return this.api + '/mission/export?id=' + retroId + '&token=' + this.$store.state.auth.idToken+'&account_id='+this.$store.state.auth.currentAccountId;
        else
            return this.api + '/retroplanning/' + retroId + '/send?export=true&token=' + this.$store.state.auth.idToken;
    },
    //ouverture de la modale de confirmation de confirm
    async openConfirmModal(link, options) {
      if (await this.$refs.confirmModal.open(this.$t(link.confirm.label), this.$t(link.confirm.message), options)) {
        if(link.url){
          //GenericDataService.postData(link.url).then((response) => {
           this.$emit('refreshData', link.refreshData.apiUrl, link.refreshData.mutation);
            this.$store.dispatch('ADD_SNACK', { message : this.$t('successActionConfirmed') }).then((e) => {
              this.$store.dispatch("REMOVE_SNACK")
            });
            this.$forceUpdate()
          //})
        }
        if(link.route) this.$router.push({ path: link.route });
        if(link.refreshData && link.refreshData.apiUrl && !link.url) this.$emit('refreshData', link.refreshData.apiUrl, link.refreshData.mutation);
        if(link.action == "delete") this.$router.push({ name: link.name });
        if(link.route || (link.refreshData && link.refreshData.apiUrl) || link.action == "delete") {
          this.$store.dispatch('ADD_SNACK', { message : this.$t('successActionConfirmed') }).then((e) => {
              this.$store.dispatch("REMOVE_SNACK")
          });
        }
        
      }
    },
    //verifie si le menu doit etre affiché
    canShowMenu(action) {
        let canShowMenu = false;
        let vm = this;
        action.menus.forEach(function (menu, menu_key) {
            menu.forEach(function (menu_action, menu_action_key) {
                if(!menu_action.hide && (!menu_action.rights || vm.$func.hasRight(menu_action.rights)))
                    canShowMenu = true;
            });
        });

        return canShowMenu;
    },
    customRefresh(response){
      this.$store.dispatch("lead/SET_SUPERLEAD_AND_OPEN_DIALOG", response.data.data);
    }
  },
};
</script>
